body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Nanumgothiccoding';
  src: url('https://assets.website-files.com/5de1294f3719a1d5cfd82e59/5de953e9519095e5d321dd59_NanumGothicCoding-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Nanumgothiccoding';
  src: url('https://assets.website-files.com/5de1294f3719a1d5cfd82e59/5de953e84b9e5a3fe89de727_NanumGothicCoding-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
