body {
  /*background: linear-gradient(blue, white);*/
  background: white;
}

#root {
  width: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    width: 20%;
    height: 20%;
    margin-bottom: 30px;
  }
}

.App-header {
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background: white;
}

.App-link {
  color: #61dafb;
}

@keyframes Spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes FooterGradientAnimation {
  0% {
    background-position: 0% 99%;
  }
  50% {
    background-position: 100% 2%;
  }
  100% {
    background-position:0% 99%;
  }
}

@keyframes popUpwards {
  0% {
  -webkit-transform:matrix(.97,0,0,1,0,12);
  transform:matrix(.97,0,0,1,0,12);
  opacity:0
  }
  20% {
    -webkit-transform:matrix(.99,0,0,1,0,2);
    transform:matrix(.99,0,0,1,0,2);
    opacity:.7
  }
  40% {
    -webkit-transform:matrix(1,0,0,1,0,-1);
    transform:matrix(1,0,0,1,0,-1);
    opacity:1
  }
  70% {
    -webkit-transform:matrix(1,0,0,1,0,0);
    transform:matrix(1,0,0,1,0,0);
    opacity:1
  }
  100% {
    -webkit-transform:matrix(1,0,0,1,0,0);
    transform:matrix(1,0,0,1,0,0);
    opacity:1
  }
}

.App-header-paragraph {
  font-family: sofia-pro, sans-serif;
  color: black;
  font-size: 18px;
  line-height: 28px;
  -webkit-animation: popUpwards 2s forwards ease-in-out;
  -moz-animation: popUpwards 2s forwards ease-in-out;
  -webkit-animation: popUpwards 2s forwards ease-in-out;
  animation: popUpwards 2s forwards ease-in-out;
}

.App-gradient-container {
  height: 200vh;
  will-change: background;
  transform: translateZ(0);
  position: absolute;
  top: 40vh;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(226deg,#FED8FF,#F9D9C1,#D0E5FB,#FFF7C9,#DFFCD1);
  background-size: 600% 600%;
  -webkit-animation: FooterGradientAnimation 10s ease infinite;
  -moz-animation: FooterGradientAnimation 10s ease infinite;
  -webkit-animation: FooterGradientAnimation 10s ease infinite;
  animation: FooterGradientAnimation 10s ease infinite;
  z-index: -1;
}

.Padded-content {
 padding: 0% 4% 4% 4%;
}

.white-background {
  background: rgba(255,255,255, 0.8);
  margin-top: 4%;
  padding: 10%;
  border-radius: 8px;
  box-shadow: 0 6px 16px rgba(231, 225, 212, 0.4);
}

.App-gradient-header {
  font-family: sofia-pro, sans-serif;
  color: black;
  font-size: 24px;
  line-height: 40px;
  font-weight: bold;
  text-align: left;
  padding-bottom: 10px;
/*  border-bottom: 4px solid #eee;*/
}

.App-gradient-paragraph {
  font-family: sofia-pro, sans-serif;
  color: black;
  font-size: 18px;
  line-height: 24px;
/*  font-weight: bold;*/
  text-align: left;
}

